import { addGaEvent } from 'common/primitives/analytics';
import VitraColumns from 'common/primitives/columns/vitraColumns';
import VitraProduct from 'common/primitives/product/vitraProduct';
import { useTranslations } from 'common/primitives/translations';
import { fontStyles, media } from 'common/styles';
import { css, cx } from 'linaria';
import React from 'react';
import { FindVitraContentLocation } from '../find-vitra-types';

interface CommonLocationProductsProps {
    location: FindVitraContentLocation;
    className?: string;
}

export const styles = {
    columnsCentered: css`
        justify-content: center;
    `,
    subTitle: css`
        text-align: center;
    `
};

const CommonLocationProducts: React.FunctionComponent<CommonLocationProductsProps> = ({ location, className }) => {
    const translations = {
        title: useTranslations('community_products_title', 'Products at this location'),
        subtitle: useTranslations(
            'community_products_subtitle',
            'The versions of the products shown here may vary from those available on site.'
        )
    };

    const columns = [
        { media: media.mobileX, columns: 2 },
        { media: media.tablet, columns: 3 },
        { media: media.desktop, columns: 4 }
    ];

    if (!location.products || location.products.length === 0) {
        return null;
    }

    const handleOnClick = (product: Product) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        addGaEvent({
            eventCategory: 'Find Vitra',
            eventAction: 'POI Details',
            eventLabel: `${location.cn} | Product Click | ${product.name}`
        });
        window.open(product.link.href);
    };

    return (
        <div className={className}>
            <h2 className={fontStyles.h2}>{translations.title}</h2>
            {translations.subtitle !== 'community_products_subtitle' && (
                <p className={cx(fontStyles.p, styles.subTitle)}>{translations.subtitle}</p>
            )}
            <VitraColumns
                columns={columns}
                className={cx(location.products.length < 4 && styles.columnsCentered, className)}
            >
                {location.products.map((product: Product, key: any) => (
                    <VitraProduct product={product} lazy={true} onClick={handleOnClick(product)} />
                ))}
            </VitraColumns>
        </div>
    );
};

export default CommonLocationProducts;
