import ToggleInput from 'common/primitives/forms/inputs/toggleInput';
import { canUseDOM } from 'exenv';
import cookie from 'js-cookie';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useConfig } from './useBoostrap';

const FEATURES_COOKIE_KEY = `featureFlags`;

export const featuresToggled = (): boolean => {
    const s = cookie.get(FEATURES_COOKIE_KEY);
    return s === 'true';
};

export enum FeatureFlags {
    prices_fromPrice_Hero = 'prices_fromPrice_Hero',
    prices_fromPrice_productListing = 'prices_fromPrice_productListing',
    log_traces = 'log_traces',
    prices_productInfo = 'prices_productInfo',
    ct_wishlist = 'ct_wishlist',
    ct_cart = 'ct_cart',
    ct_account = 'ct_account',
    googlepay = 'googlepay'
}

export function useFeature(key: FeatureFlags): boolean {
    if (!canUseDOM || process.env.NODE_ENV === 'test') {
        return false;
    }

    // If the feature is set via the config it will be activated directly
    const featuresFromConfig = useConfig<FeatureFlags[]>('features', []);
    if (!Array.isArray(featuresFromConfig)) {
        console.warn('misconfigured features config');
    } else if ((featuresFromConfig as FeatureFlags[]).includes(key)) {
        return true;
    }

    // If the features toggle is set all features are allowed
    if (featuresToggled()) {
        return true;
    }

    // Activtae a feature via the query string
    const qs = queryString.parse(window.location.search);
    const hasFeature = (qs.features || '').split(',').includes(key);
    return hasFeature;
}

/**
 * Feature flags toggle
 */
export const VitraFeatureToggle: React.FunctionComponent = () => {
    const allowedHosts = ['localhost', 'www.vitra.dev'];
    const [selected, setSelected] = useState<boolean>(false);
    useEffect(() => {
        if (!canUseDOM) {
            return;
        }
        setSelected(featuresToggled());
    }, []);

    const getCookieDomain = (): string => {
        const hostnames = window.location.hostname.split('.');
        hostnames.shift();
        return hostnames.join('.');
    };

    const handleToggleChange = (value: boolean) => {
        setSelected(!selected);
        document.body.innerHTML +=
            '<div style="position:fixed;width:100%;height:100%;opacity:0.8;z-index:100;background:#000;display: flex; justify-content: center; align-items: center;z-index: 10000000;top: 0; left:0; color: white;"><span>Switching now. Please wait a moment...</span></div>';
        cookie.set(FEATURES_COOKIE_KEY, String(value), {
            secure: true,
            expires: 365,
            domain: getCookieDomain(),
            path: '/'
        });
        window.setTimeout(() => {
            window.location.reload();
        }, 2000);
    };

    if (!canUseDOM || !allowedHosts.includes(window.location.hostname)) {
        return null;
    }
    return (
        <div style={{ position: 'fixed', bottom: '20px', left: '20px', zIndex: 100000 }}>
            <ToggleInput
                testId="ct-feature-toggle"
                label={selected ? 'CT ON' : 'CT OFF'}
                onChange={handleToggleChange}
                defaultChecked={selected}
            />
        </div>
    );
};
