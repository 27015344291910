import IconClock from 'common/primitives/icons/components/V1Clock';
import { useTranslations } from 'common/primitives/translations';
import { colors, rh } from 'common/styles';
import { css, cx } from 'linaria';
import React from 'react';
import { FindVitraContentLocation } from '../find-vitra-types';

const styles = {
    locationIsOpen: css`
        margin: ${rh(0.25)} 0 0;
        font-size: 15px;
        .isopen__copy {
            display: inline;
            .copy__highlight {
                color: #348c42;
            }
        }
        svg {
            fill: ${colors.findVitraColorGray};
            width: 14px;
            height: 14px;
            margin-right: 6px;
            position: relative;
            top: 2px;
        }
        ul.isopen__list {
            display: inline;
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                display: inline;
                &:not(:last-child):after {
                    content: ', ';
                }
            }
        }
    `
};

interface CommonSearchResultListLocationIsOpenProps {
    location: FindVitraContentLocation;
    className?: string;
}

const CommonSearchResultListLocationIsOpen: React.FunctionComponent<CommonSearchResultListLocationIsOpenProps> = (
    props
) => {
    const translations = {
        open: useTranslations('community_contact_opening_hours_open', 'Open now'),
        closed: useTranslations('community_contact_opening_hours_closed', 'Closed'),
        openAllDay: useTranslations('community_contact_opening_hours_open_all_day', 'Open all day'),
        weekdays: {
            monday: useTranslations('weekdays_shortcurts_monday', 'Mon'),
            tuesday: useTranslations('weekdays_shortcurts_tuesday', 'Tue'),
            wednesday: useTranslations('weekdays_shortcurts_wednesday', 'Wed'),
            thursday: useTranslations('weekdays_shortcurts_thursday', 'Thu'),
            friday: useTranslations('weekdays_shortcurts_friday', 'Fri'),
            saturday: useTranslations('weekdays_shortcurts_saturday', 'Sat'),
            sunday: useTranslations('weekdays_shortcurts_sunday', 'Sun')
        }
    };

    const location = props.location;

    const d = new Date();
    const weekdayMapping = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const weekdayMappingTodayString = weekdayMapping[d.getDay()];

    let openingHoursToday = null;

    if (location.openNow) {
        openingHoursToday = location.openingHours.find((o) => o.key === weekdayMappingTodayString);
    }

    return (
        <div className={cx(props.className, styles.locationIsOpen)}>
            <IconClock />
            <div className="isopen__copy">
                <span className={cx(location.openNow && 'copy__highlight')}>
                    {location.openNow ? translations.open : translations.closed}
                </span>
                {openingHoursToday && <span>, {(translations as any).weekdays[weekdayMappingTodayString]}</span>}
            </div>
            &nbsp;
            <ul className="isopen__list">
                {!location.openAroundTheClock &&
                    openingHoursToday &&
                    openingHoursToday.openingHours.map((dayRange, idx) => (
                        <li key={idx}>{`${dayRange.open} - ${dayRange.closed}`}</li>
                    ))}
                {location.openAroundTheClock && translations.openAllDay}
            </ul>
        </div>
    );
};

export default CommonSearchResultListLocationIsOpen;
