import type { WishlistBffContext } from 'common/hooks/useWishlist';
import { useWishlistBffContext } from 'common/hooks/useWishlist';
import { addGaEvent } from 'common/primitives/analytics';
import IconFacebook from 'common/primitives/icons/components/V1Facebook';
import IconInstagram from 'common/primitives/icons/components/V1Instagram';
import IconPinterest from 'common/primitives/icons/components/V1Pinterest';
import IconTwitter from 'common/primitives/icons/components/V1Twitter';
import IconArrowLeft from 'common/primitives/icons/components/V2ArrowLeftMedium';
import VitraLink from 'common/primitives/links/vitraLink';
import { useTranslations } from 'common/primitives/translations';
import { colors, containerCentered, linkColor, media, rh } from 'common/styles';
import { fontStyles } from 'common/styles/fonts';
import { canUseDOM } from 'exenv';
import { css, cx } from 'linaria';
import normalizeUrl from 'normalize-url';
import React, { Fragment, useEffect } from 'react';
import { Link } from 'wouter';
import { FindVitraContentLocation } from '../find-vitra-types';
import { useStateValue } from '../state/find-vitra.state-provider';
import CommonLocationButtons from './commonLocationButtons';
import CommonLocationImages from './commonLocationImages';
import CommonLocationOpeningHours from './commonLocationOpeningHours';
import CommonLocationProducts from './commonLocationProducts';
import CommonLocationTags from './commonLocationTags';
import CommonMap from './commonMap';
import CommonOverlay from './commonOverlay';
export const styles = {
    desktopDetail: css``,
    detailHead: css`
        text-align: center;
        padding: 0 16px;
        margin-bottom: ${rh(1.5)};
        position: relative;
    `,
    detailHeadTitle: css`
        max-width: 600px;
        margin: 0 auto 16px;
    `,
    detailHeadDescription: css`
        max-width: 640px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: calc(${rh(1)} - 8px);
    `,
    detailHeadTags: css`
        max-width: 760px;
        margin: 0 auto;
        justify-content: center;
    `,
    detailHeadBack: css`
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: ${rh(1)};
        ${linkColor(colors.black, colors.findVitraColorRed)};
        text-decoration: none;
        ${media.tablet} {
            position: absolute;
            left: 0;
            top: 12px;
        }
        &:hover {
            cursor:pointer;
            color: ${colors.findVitraColorRed};
            svg {
                path {
                    fill: ${colors.findVitraColorRed};
                }
            }
        }
    }
    `,
    detailHeadBackCopy: css`
        margin-left: 8px;
        display: inline-flex;
    `,
    detailFooter: css`
        display: flex;
        flex-wrap: wrap;
        margin-bottom: ${rh(2)};
    `,
    detailFooterContact: css`
        flex: 0 1 100%;
        ${media.tablet} {
            flex: 0 1 50%;
        }
    `,
    detailFooterContactAddress: css`
        width: 100%;
        text-align: center;
        ${media.tablet} {
            text-align: left;
            width: 50%;
            padding-right: 16px;
        }
        display: inline-block;
        vertical-align: top;
        margin-bottom: 32px;
    `,
    detailFooterContactAddressTitle: css`
        font-weight: 600;
        font-size: 17px;
    `,
    detailFooterContactAddressDataLink: css`
        text-decoration: none;
        ${linkColor(colors.findVitraColorBlack, colors.findVitraColorRed)};
    `,
    detailFooterContactAddressDataLinkPhone: css`
        @media only screen and (min-width: 769px) {
            pointer-events: none;
        }
    `,
    detailFooterContactAddressSocial: css`
        display: flex;
        align-items: center;
        justify-content: center;
        ${media.tablet} {
            justify-content: flex-start;
        }
        margin-top: 32px;
        > * {
            margin-left: 19px;
            margin-right: 19px;
            ${media.tablet} {
                margin-left: 0;
                margin-right: 38px;
            }
        }
        svg {
            width: 22px;
            height: 22px;
        }
    `,
    detailFooterContactButtons: css`
        ${media.tablet} {
            padding-right: 16px;
        }
    `,
    detailFooterMap: css`
        position: relative;
        margin-top: ${rh(1)};
        flex: 0 1 100%;
        padding-top: 100%;
        ${media.tablet} {
            margin-top: 0;
            flex: 0 1 50%;
            padding-top: 50%;
        }
        > div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    `,
    detailProducts: css`
        margin-bottom: ${rh(2)};
    `
};

const DesktopDetail: React.FunctionComponent<any> = ({ params }) => {
    const t = {
        community_navigation_back_to_search: useTranslations('community_navigation_back_to_search', 'Back to search'),
        community_contact_information_title: useTranslations(
            'community_contact_information_title',
            'Contact Information'
        )
    };

    const [state, dispatch]: any = useStateValue();
    const wishlistContext: WishlistBffContext = useWishlistBffContext();

    /**
     * We have some shitty links in the data
     * sometimes missing protocol or other faulty values
     */
    const normUrl = (url: string): string => {
        try {
            return normalizeUrl(url);
        } catch (e) {
            return '#';
        }
    };

    const location = state.locations.find((loc: FindVitraContentLocation) => loc._code === params.retailerId);

    if (typeof location === 'undefined') {
        return null;
    }

    useEffect(() => {
        if (canUseDOM) {
            window.scrollTo(0, 0);
        }
        dispatch({ type: 'SET_ACTIVE_LOCATION', location });
    }, []);

    return (
        <Fragment>
            <CommonOverlay />
            <div className={cx(containerCentered, styles.desktopDetail)}>
                <div className={styles.detailHead}>
                    <Link className={styles.detailHeadBack} href="/">
                        <IconArrowLeft />
                        <span className={styles.detailHeadBackCopy}>{t.community_navigation_back_to_search}</span>
                    </Link>
                    <h1 className={cx(fontStyles.h1, styles.detailHeadTitle)}>{location.cn}</h1>
                    {location.description && (
                        <p
                            className={cx(styles.detailHeadDescription, fontStyles.p)}
                            dangerouslySetInnerHTML={{ __html: location.description }}
                        />
                    )}
                    <CommonLocationTags className={styles.detailHeadTags} location={location} limit={12} />
                </div>
                <CommonLocationImages location={location} />
                <div className={styles.detailFooter}>
                    <div className={styles.detailFooterContact}>
                        <div className={styles.detailFooterContactAddress}>
                            <h2 className={styles.detailFooterContactAddressTitle}>
                                {t.community_contact_information_title}
                            </h2>
                            {location.sa}
                            <br />
                            {location.city}
                            <br />
                            {location.phone && (
                                <Fragment>
                                    <a
                                        href={`tel:${location.phone}`}
                                        className={cx(
                                            styles.detailFooterContactAddressDataLink,
                                            styles.detailFooterContactAddressDataLinkPhone
                                        )}
                                    >
                                        {location.phone}
                                    </a>
                                    <br />
                                </Fragment>
                            )}
                            {location.fax && (
                                <Fragment>
                                    {location.fax}
                                    <br />
                                </Fragment>
                            )}
                            <VitraLink
                                {...location.link}
                                href={normUrl(location.link.href)}
                                className={styles.detailFooterContactAddressDataLink}
                            />
                            {location.social && (
                                <div className={styles.detailFooterContactAddressSocial}>
                                    {location.social
                                        .filter((ls: any) => ls.href && ls.type)
                                        .map((s: any, i: number) => (
                                            <a
                                                key={i}
                                                href={normUrl(s.href)}
                                                onClick={(e: any) => {
                                                    const { href, target } = e.currentTarget;
                                                    e.preventDefault();
                                                    addGaEvent({
                                                        eventCategory: 'Find Vitra',
                                                        eventAction: 'POI Details',
                                                        eventLabel: `${location.cn} | ${s.type.toLowerCase()} Link`
                                                    });
                                                    window.open(href, target);
                                                }}
                                                target="_blank"
                                                rel="noReferrer"
                                            >
                                                {s.type.toLowerCase() === 'instagram' && <IconInstagram />}
                                                {s.type.toLowerCase() === 'facebook' && <IconFacebook />}
                                                {s.type.toLowerCase() === 'pinterest' && <IconPinterest />}
                                                {s.type.toLowerCase() === 'twitter' && <IconTwitter />}
                                            </a>
                                        ))}
                                </div>
                            )}
                        </div>
                        <CommonLocationOpeningHours location={location} />
                        <CommonLocationButtons
                            className={styles.detailFooterContactButtons}
                            location={location}
                            withWishlist={wishlistContext.getWishlistCount && wishlistContext.getWishlistCount() > 0}
                        />
                    </div>
                    <div className={styles.detailFooterMap}>
                        <CommonMap
                            markers={[location.coords]}
                            center={location.coords}
                            options={{ disableDefaultUI: false }}
                            defaultZoom={12}
                        />
                    </div>
                </div>
                <CommonLocationProducts className={styles.detailProducts} location={location} />
            </div>
        </Fragment>
    );
};

export default DesktopDetail;
