import copy from 'clipboard-copy';
import useToggle from 'common/hooks/useToggle';
import IconShare from 'common/primitives/icons/components/V1Share2';
import { useTranslations } from 'common/primitives/translations';
import { colors, media, rh } from 'common/styles';
import { css, cx } from 'linaria';
import React, { Fragment } from 'react';

const styles = {
    shareButton: css`
        display: flex;
        justify-content: center;
        margin-left: auto;
        align-items: center;
        font-weight: 100;
        position: relative;
        top: 1px;
        &:hover {
            cursor: pointer;
        }
    `,
    shareButtonSuccess: css`
        text-align: center;
        margin-top: ${rh(1)};
        line-height: ${rh(1)};
        background: ${colors.findVitraColorSilver};
        padding: ${rh(1)};
        ${media.tablet} {
            margin-top: 0;
            position: absolute;
            top: 0px;
            left: 0;
            z-index: 2;
            height: 100%;
            width: 100%;
        }
    `,
    shareButtonIcon: css`
        margin-left: 10px;
    `
};

const CommonSearchShareButton: React.FunctionComponent = () => {
    const translations = {
        clipboardMessage: useTranslations(
            'community_search_results_share_clipboard',
            'The link for sharing has been pasted into the clipboard.'
        ),
        shareTitle: useTranslations('community_search_results_share_title', 'Share')
    };

    const [isCopied, toggleIsCopied] = useToggle(false);

    const handleOnClick = () => {
        toggleIsCopied();
        setTimeout(() => {
            toggleIsCopied();
        }, 3000);
        copy(window.location.href);
    };

    return (
        <div onClick={handleOnClick} className={cx(styles.shareButton, isCopied && styles.shareButtonSuccess)}>
            {isCopied && <span>{translations.clipboardMessage}</span>}
            {!isCopied && (
                <Fragment>
                    <span>{translations.shareTitle}</span>
                    <IconShare className={styles.shareButtonIcon} />
                </Fragment>
            )}
        </div>
    );
};

export default CommonSearchShareButton;
