import { useTranslations } from 'common/primitives/translations';
import { colors } from 'common/styles';
import { css, cx } from 'linaria';
import React from 'react';

interface CommonPaginationProps {
    className?: string;
    currentPage: number;
    numPages: number;
    translationNext: string;
    translationPrev: string;
    onSetPage: (page: number) => void;
}

const styles = {
    paginationWrapper: css`
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 16px;
        position: relative;
    `,
    paginationGoto: css`
        position: absolute;
        &:hover {
            cursor: pointer;
        }
    `,
    paginationGotoNext: css`
        right: 16px;
        text-align: right;
    `,
    paginationGotoPrevious: css`
        left: 16px;
        text-align: left;
    `,
    paginationNumbers: css`
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${colors.findVitraColorGray};
    `,
    numberItem: css`
        height: 44px;
        display: flex;
        align-items: center;
        &:hover {
            cursor: pointer;
        }
    `,
    numberItemCopy: css`
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 30px;
        height: 30px;
        border-radius: 30px;
        padding: 0 8px;
    `,
    numberItemCopyActive: css`
        background: ${colors.findVitraColorBlack};
        color: white;
    `
};

const CommonPagination: React.FunctionComponent<CommonPaginationProps> = ({
    className,
    currentPage,
    numPages,
    translationNext,
    translationPrev,
    onSetPage
}) => {
    const translations = {
        next: useTranslations('community_search_results_pagination_next', 'Next'),
        previous: useTranslations('community_search_results_pagination_previous', 'Previous')
    };

    if (numPages === 1) {
        return null;
    }

    /**
     * The Page display ...,5,6,7,...
     */
    const showPages = 5;
    const slicePagesToDisplay = (curPage: number, nPages: number, start = 1) => {
        const paging = [];
        /* tslint:disable-next-line */
        let i = Math.min(nPages + start - showPages, Math.max(start, curPage - ((showPages / 2) | 0)));
        const end = i + showPages;
        while (i < end) {
            paging.push(i++);
        }
        return paging;
    };
    const pages =
        numPages > showPages
            ? // creates an array in Form of [N,5,6,7,N]
              slicePagesToDisplay(currentPage, numPages)
            : // creates an array in Form of [1,2,3,N]
              Reflect.apply(Array, null, new Array(numPages)).map((_: any, i: number) => i + 1);

    /**
     * MouseEvents
     */
    const handleSetPage = (page: number) => (evt: React.MouseEvent) => {
        onSetPage(page);
    };

    return (
        <div className={cx(styles.paginationWrapper, className)}>
            {currentPage !== 1 && (
                <div
                    className={cx(styles.paginationGoto, styles.paginationGotoPrevious)}
                    onClick={handleSetPage(currentPage - 1)}
                >
                    {translations.previous}
                </div>
            )}
            <div className={styles.paginationNumbers}>
                {pages.map((p: number, k: number) => (
                    <div key={k} className={styles.numberItem} onClick={handleSetPage(p)}>
                        <span className={cx(styles.numberItemCopy, p === currentPage && styles.numberItemCopyActive)}>
                            {p}
                        </span>
                    </div>
                ))}
            </div>
            {currentPage < numPages && (
                <div
                    className={cx(styles.paginationGoto, styles.paginationGotoNext)}
                    onClick={handleSetPage(currentPage + 1)}
                >
                    {translations.next}
                </div>
            )}
        </div>
    );
};

export default CommonPagination;
