import { BffCustomerAddress } from 'common/graphql/sdk';
import { addCheckoutActionEvent } from 'common/primitives/analytics';
import { BlankButton } from 'common/primitives/buttons';
import { FakeInput, MaskedInput, RadioInput, TextInput } from 'common/primitives/forms';
import AddressInput, { AddressProposalRetrieveResultItem } from 'common/primitives/forms/components/addressInput';
import globalStyle, { formRowStyles, formRowWrapperStyles } from 'common/primitives/forms/styles';
import { useTranslations } from 'common/primitives/translations';
import { colors, linkColor } from 'common/styles';
import { css, cx } from 'linaria';
import defaults from 'lodash/defaults';
import get from 'lodash/get';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import SelectInput from '../forms/inputs/selectInput';
import { flattenToDotNotation } from './utils/flattenFormValues';
import { AddressInputDefinition, AddressState, AdressInputArrangement } from './vitraAddressFormTypes';

const styles = defaults(
    {
        hintLink: css`
            ${linkColor(colors.black, colors.primary)};
        `,
        toggleAddressInputHint: css`
            display: block;
        `,
        stateSelect: css`
            select {
                font-weight: 500;
            }
        `
    },
    globalStyle
);

interface VitraAddressFormProps {
    /**
     * Register ref function must be passed to each field
     */
    register: () => void;

    /**
     * Reset default values - used after autocompletion
     */
    reset: any;

    /**
     * Errors must be passed to feilds to trigger error display
     */
    errors: any;

    /**
     * Tell the form that we have an error. Needed for addressInput auto completion
     */
    setError: any;

    /**
     * get all values of the form (without a rerender like watch() does trigger)
     */
    getValues: any;

    /**
     * Required for the masked input used for postcodes. (react-hook-form)
     */
    control: any;

    /**
     * Triggers the validation for the updated values. Needed for addressInput auto completion
     */
    triggerValidation: any;

    /**
     * A prefix to prepend to field names and refers to a nested object,
     * e.g.: [fieldNamePrefix].firstname
     */
    fieldNamePrefix?: 'shippingAddress.' | 'billingAddress.';

    /**
     * Some fields must be explicitly enabled
     */
    renderSpecialFields?: 'customerType'[];

    /**
     * Some fields may be suppresed from the addressInputArrangement
     */
    hideFields?: string[];

    /**
     * Some fields must be disabled
     */
    disabledFields?: (keyof BffCustomerAddress)[];

    /**
     * The country code  needed for config
     */
    countryCode: string;

    /**
     * The country name to display, e.g. "Deutschland"
     */
    countryName: string;

    /**
     * The states if there are any
     */
    addressStates?: AddressState[];

    /**
     * Postal Code Blacklist Functions
     */
    postalCodeIsBlacklisted?: (code: string) => boolean;

    /**
     * Postal Code regex validation via config
     */
    postalCodeRegexIsValid?: (code: string) => boolean;

    /**
     * Postal Code Format - if given an input mask is created instead of a TextField
     */
    postalCodeFormat?: string | ((v?: any) => string);

    /**
     * The AdressInputArrangement
     */
    adressInputArrangement: AdressInputArrangement;

    /**
     * The defaultValues object passed from react-hook-form
     */
    defaultValues?: any;
}

// Check if we have a postcode pattern in the config
// we then sanitize the return value from the api
export const cleanPostCode = (postCodeFormat: string | undefined | ((s: string) => string), str: string): string => {
    if (!postCodeFormat) {
        return str;
    }
    let pattern = typeof postCodeFormat === 'function' ? postCodeFormat(str) : postCodeFormat;

    const charMap = {
        '*': '[a-z,A-Z,0-9,-,+,*]',
        '#': '[0-9,-]',
        $: '[a-z, A-Z]'
    };

    // We must iterate over each character in the input and test it to the patterm
    pattern = pattern.replace(/[*#$+-]/g, (match) => (charMap as any)[match]);

    // create an array of either an item of the charMap or a whitespace
    const patternParts = pattern.match(/(\[.*?\])|\s/g);

    const strParts = str.split('');

    let p = patternParts?.shift();

    const sanitized = [];
    for (let i = 0; i < strParts.length; i++) {
        const char = strParts[i];
        const regex = new RegExp(p!);
        if (regex.test(char)) {
            if (char === '-' && sanitized.length === 0) {
                // - symbol isnt allowed at the beginning of the string
                continue;
            } else {
                sanitized.push(char);
                p = patternParts?.shift();
            }
        }
    }
    return sanitized.join('');
};

const VitraAddressForm: React.FunctionComponent<VitraAddressFormProps> = ({
    reset,
    register,
    control,
    errors,
    setError,
    getValues,
    triggerValidation,
    countryCode,
    countryName,
    addressStates,
    fieldNamePrefix,
    renderSpecialFields,
    hideFields,
    disabledFields,
    postalCodeIsBlacklisted,
    postalCodeRegexIsValid,
    postalCodeFormat,
    adressInputArrangement,
    defaultValues
}) => {
    const translations = {
        titleOptionLabel: useTranslations('address.fields.salutation.label', 'Salutation'),
        titleOptionMr: useTranslations('address.fields.salutation.mr', 'Mr'),
        titleOptionMrs: useTranslations('address.fields.salutation.ms', 'Ms'),
        titleOptionMrmrs: useTranslations('address.fields.salutation.mx', 'Mx'),
        customerTypeLabel: useTranslations('address.fields.customer_type.label', 'You are a:'),
        customerTypeB2b: useTranslations('address.fields.customer_type.b2b', 'Business customer'),
        customerTypeB2c: useTranslations('address.fields.customer_type.b2c', 'Private customer'),
        firstName: useTranslations('address.fields.first_name', 'First name'),
        lastName: useTranslations('address.fields.lasd_name', 'Last name'),
        company: useTranslations('address.fields.company', 'Company name'),
        addressAutoComplete: useTranslations('address.auto_complete.hint', 'Enter the first line of your address'),
        addressAutocompleteManually: useTranslations('address.auto_complete.disable', 'Enter your address manually'),
        additionalAddressInfo: useTranslations(
            'address.additional_info.hint',
            'Additional address information (e.g .: apt, apartment, building)'
        ),
        streetNumber: useTranslations('address.fields.street_number', 'Nr.'),
        streetName: useTranslations('address.fields.street_name', 'Street'),
        postalCode: useTranslations('address.fields.postal_code', 'ZIP'),
        postalCodeError: useTranslations('address.fields.postal_code_innvalid_error', 'Invalid Postcode'),
        city: useTranslations('address.fields.city', 'City'),
        phone: useTranslations('address.fields.phone', 'Your phone number'),
        phoneHint: useTranslations(
            'address.fields.phone_hint',
            'We need a correct telephone number for the shipping company to coordinate the delivery date. This information is used exclusively for this purpose.'
        ),
        region: useTranslations('address.fields.region', 'State'),
        country: useTranslations('address.fields.country', 'Country'),
        country_disabled_hint: useTranslations(
            'address.fields.country_diabled_hint',
            'If you want to shop in another country, you have to change the country settings of the online shop.'
        ),
        locality: useTranslations('address.fields.locality', 'Locality'),
        apartment: useTranslations('address.fields.apartment', 'Apartment'),
        buildingName: useTranslations('address.fields.building', 'Building name'),
        errorRequired: useTranslations('address.fields.errors.required', 'Please fill in the mandatory field')
    };

    type translationKeys = keyof typeof translations;

    // Error Message
    const getLabel = (name: translationKeys): string => {
        return translations[name];
    };

    // We must separate the purpose from the name to result in e.g. billing.firstName
    const fieldNamePrefixString = fieldNamePrefix ?? '';

    // All about address autocomplete
    const additionalRef = useRef<any>(null);
    const [showAllAddressInputs, setShowAllAddressInputs] = useState(false);
    const [addressAutocompleteDone, setAddressAutocompleteDone] = useState(false);
    const handleShowAllAddressInputs = (e: React.MouseEvent) => {
        e.preventDefault();
        addCheckoutActionEvent(`Client Data | Manual Entry`);
        setShowAllAddressInputs(true);
    };

    useEffect(() => {
        const hasValue = [
            `${fieldNamePrefixString}streetName`,
            `${fieldNamePrefixString}streetNumber`,
            `${fieldNamePrefixString}postalCode`,
            `${fieldNamePrefixString}city`
        ].some((el) => {
            if (defaultValues && get(defaultValues, el) !== undefined) {
                return true;
            }
            return false;
        });

        if (hasValue) {
            setAddressAutocompleteDone(true);
            setShowAllAddressInputs(true);
        }
    }, [defaultValues]);

    const handleOnAdressAutocomplete = async (a: AddressProposalRetrieveResultItem | undefined) => {
        if (a) {
            const newValues = {
                // ATTENTION
                // Because react-hook-forms getValues returns a flat object and dafaultValues is a nested object,
                // we need to flatten the defaultValues object. Otherwise react-hook-form would pick/prefer the defaultValues
                // and the actual form value might get lost
                ...flattenToDotNotation(defaultValues),
                ...getValues(),
                addressAutocomplete: '',
                [`${fieldNamePrefixString}streetName`]: a.Street,
                [`${fieldNamePrefixString}streetNumber`]: a.BuildingNumber,
                [`${fieldNamePrefixString}postalCode`]: cleanPostCode(postalCodeFormat, a.PostalCode),
                [`${fieldNamePrefixString}city`]: a.City
            };

            const validationFields = [
                `${fieldNamePrefixString}streetName`,
                `${fieldNamePrefixString}streetNumber`,
                `${fieldNamePrefixString}postalCode`,
                `${fieldNamePrefixString}city`
            ];

            if (a.ProvinceName && countryCode.toUpperCase() === 'US') {
                newValues[`${fieldNamePrefixString}region`] = a.ProvinceCode;
                validationFields.push(`${fieldNamePrefixString}region`);
            }

            reset(newValues);
            await triggerValidation(validationFields);

            if (additionalRef && additionalRef.current) {
                setTimeout(() => {
                    additionalRef.current.focus();
                }, 200);
            }
        }

        setAddressAutocompleteDone(true);
        setShowAllAddressInputs(true);
    };

    if (
        typeof errors[`${fieldNamePrefixString}streetName`] !== 'undefined' ||
        typeof errors[`${fieldNamePrefixString}streetNumber`] !== 'undefined' ||
        typeof errors[`${fieldNamePrefixString}postalCode`] !== 'undefined' ||
        typeof errors[`${fieldNamePrefixString}city`] !== 'undefined'
    ) {
        setError('addressAutocomplete');
    }

    const renderFieldByName = (field: AddressInputDefinition, reg: any) => {
        if (hideFields?.includes(field.name)) {
            return null;
        }
        switch (field.name) {
            case 'customerType':
                if (!renderSpecialFields?.includes(field.name)) {
                    return null;
                }
                return (
                    <RadioInput
                        name="customerType"
                        label={translations.customerTypeLabel}
                        options={[
                            {
                                name: translations.customerTypeB2c,
                                value: 'b2c'
                            },
                            {
                                name: translations.customerTypeB2b,
                                value: 'b2b'
                            }
                        ]}
                        inputRef={reg}
                    />
                );
            case 'salutation':
                return (
                    <RadioInput
                        name={`${fieldNamePrefixString}${field.name}`}
                        label={translations.titleOptionLabel}
                        options={[
                            {
                                name: translations.titleOptionMrs,
                                value: 'MS'
                            },
                            {
                                name: translations.titleOptionMr,
                                value: 'MR'
                            },
                            {
                                name: translations.titleOptionMrmrs,
                                value: 'MRMS'
                            }
                        ]}
                        inputRef={reg}
                    />
                );
            case 'firstName':
            case 'lastName':
                return (
                    <TextInput
                        defaultValues={defaultValues}
                        className={`width_${field.width}`}
                        name={`${fieldNamePrefixString}${field.name}`}
                        label={getLabel(field.name)}
                        showErrorMessage={true}
                        errors={errors}
                        inputRef={reg({
                            required: translations.errorRequired
                        })}
                    />
                );
            case 'company':
                return (
                    <TextInput
                        defaultValues={defaultValues}
                        className={`width_${field.width}`}
                        name={`${fieldNamePrefixString}${field.name}`}
                        label={getLabel(field.name)}
                        showErrorMessage={true}
                        errors={errors}
                        disabled={disabledFields?.includes(field.name)}
                        inputRef={reg}
                        maxLength={70}
                    />
                );
            case 'streetName':
            case 'city':
                return (
                    <TextInput
                        defaultValues={defaultValues}
                        type={showAllAddressInputs ? 'text' : 'hidden'}
                        initallyFocussed={addressAutocompleteDone}
                        className={(formRowStyles as any)[`p${field.width}`]}
                        name={`${fieldNamePrefixString}${field.name}`}
                        label={getLabel(field.name)}
                        showErrorMessage={true}
                        errors={errors}
                        inputRef={reg({
                            required: translations.errorRequired
                        })}
                    />
                );
            case 'streetNumber':
                return (
                    <TextInput
                        defaultValues={defaultValues}
                        type={showAllAddressInputs ? 'text' : 'hidden'}
                        initallyFocussed={addressAutocompleteDone}
                        className={(formRowStyles as any)[`p${field.width}`]}
                        name={`${fieldNamePrefixString}${field.name}`}
                        label={getLabel(field.name)}
                        maxLength={6}
                        showErrorMessage={true}
                        errors={errors}
                        inputRef={reg({
                            required: translations.errorRequired
                        })}
                    />
                );
            case 'region':
                return (
                    <div className={cx(!showAllAddressInputs && styles.hintHidden)}>
                        <SelectInput
                            options={addressStates || []}
                            className={cx((formRowStyles as any)[`p${field.width}`], styles.stateSelect)}
                            name={`${fieldNamePrefixString}${field.name}`}
                            label={getLabel(field.name)}
                            showErrorMessage={true}
                            errors={errors}
                            inputRef={reg({
                                required: translations.errorRequired
                            })}
                        />
                    </div>
                );
            case 'country':
                return (
                    <Fragment>
                        <FakeInput value={countryName} label={getLabel(field.name)} />
                        <TextInput
                            defaultValues={defaultValues}
                            type="hidden"
                            initallyFocussed={addressAutocompleteDone}
                            disabled={true}
                            className={(formRowStyles as any)[`p${field.width}`]}
                            name={`${fieldNamePrefixString}${field.name}`}
                            label={getLabel(field.name)}
                            showErrorMessage={true}
                            errors={errors}
                            inputRef={reg({
                                required: translations.errorRequired
                            })}
                        />
                        <div>
                            <p className={cx(styles.hint, !showAllAddressInputs && styles.hintHidden)}>
                                {translations.country_disabled_hint}
                            </p>
                        </div>
                    </Fragment>
                );
            case 'postalCode':
                return (
                    (showAllAddressInputs && postalCodeFormat && (
                        <MaskedInput
                            mask={postalCodeFormat}
                            control={control}
                            defaultValues={defaultValues}
                            initallyFocussed={addressAutocompleteDone}
                            className={(formRowStyles as any)[`p${field.width}`]}
                            name={`${fieldNamePrefixString}${field.name}`}
                            label={getLabel(field.name)}
                            showErrorMessage={true}
                            errors={errors}
                            rules={{
                                required: translations.errorRequired,
                                validate: (v: string) => {
                                    if (postalCodeIsBlacklisted && postalCodeIsBlacklisted(v)) {
                                        return translations.postalCodeError;
                                    }
                                    if (postalCodeRegexIsValid && !postalCodeRegexIsValid(v)) {
                                        return translations.postalCodeError;
                                    }
                                    return true;
                                }
                            }}
                            inputRef={reg}
                        />
                    )) || (
                        <TextInput
                            defaultValues={defaultValues}
                            type={showAllAddressInputs ? 'text' : 'hidden'}
                            className={(formRowStyles as any)[`p${field.width}`]}
                            name={`${fieldNamePrefixString}${field.name}`}
                            label={getLabel(field.name)}
                            initallyFocussed={addressAutocompleteDone}
                            showErrorMessage={true}
                            errors={errors}
                            inputRef={reg({
                                required: translations.errorRequired,
                                validate: (v: string) => {
                                    if (postalCodeIsBlacklisted && postalCodeIsBlacklisted(v)) {
                                        return translations.postalCodeError;
                                    }
                                    if (postalCodeRegexIsValid && !postalCodeRegexIsValid(v)) {
                                        return translations.postalCodeError;
                                    }
                                    return true;
                                }
                            })}
                        />
                    )
                );
            case 'locality':
            case 'buildingName':
            case 'apartment':
                return (
                    <TextInput
                        defaultValues={defaultValues}
                        type={showAllAddressInputs ? 'text' : 'hidden'}
                        initallyFocussed={addressAutocompleteDone}
                        className={(formRowStyles as any)[`p${field.width}`]}
                        name={`${fieldNamePrefixString}${field.name}`}
                        label={getLabel(field.name)}
                        inputRef={(e: any) => {
                            reg(e);
                            additionalRef.current = e;
                        }}
                    />
                );
            case 'phone':
                return (
                    <Fragment>
                        <TextInput
                            defaultValues={defaultValues}
                            type={'text'}
                            initallyFocussed={addressAutocompleteDone}
                            className={(formRowStyles as any)[`p${field.width}`]}
                            name={`${fieldNamePrefixString}${field.name}`}
                            label={getLabel(field.name)}
                            errors={errors}
                            showErrorMessage={true}
                            trimWhitespace={'all'}
                            onChange={(e) => {
                                // Remove all letters
                                const v = e.target.value;
                                e.target.value = v.replace(/[a-zA-Z]/g, '');
                            }}
                            inputRef={reg({
                                required: translations.errorRequired
                            })}
                        />
                        <p className={styles.hint}>{translations.phoneHint}</p>
                    </Fragment>
                );
            case 'additionalAddressInfo':
                return (
                    <TextInput
                        defaultValues={defaultValues}
                        name={`${fieldNamePrefixString}${field.name}`}
                        label={getLabel(field.name)}
                        showErrorMessage={true}
                        errors={errors}
                        inputRef={(e: any) => {
                            reg(e);
                            additionalRef.current = e;
                        }}
                    />
                );

            case 'addressAutoComplete':
                if (showAllAddressInputs) {
                    return null;
                }
                return (
                    <div>
                        <AddressInput
                            name="addressAutocomplete"
                            label={getLabel(field.name)}
                            onSuccess={handleOnAdressAutocomplete}
                            countryIso={countryCode}
                            errors={errors}
                            showErrorMessage={true}
                            inputRef={reg({
                                required: translations.errorRequired
                            })}
                        />
                        <BlankButton
                            className={cx(styles.hint, styles.toggleAddressInputHint)}
                            testId="toggleAddressInput"
                            onClick={handleShowAllAddressInputs}
                        >
                            {translations.addressAutocompleteManually}
                        </BlankButton>
                    </div>
                );
        }
    };
    return (
        <Fragment>
            {defaultValues?.id && <input type="hidden" name="id" ref={register} />}
            {adressInputArrangement.map((row) => (
                <div className={formRowWrapperStyles}>{row.map((i) => renderFieldByName(i, register))}</div>
            ))}
        </Fragment>
    );
};
export default VitraAddressForm;
