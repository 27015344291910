import { useTranslations } from 'common/primitives/translations';
import { media } from 'common/styles';
import { css, cx } from 'linaria';
import React from 'react';
import { FindVitraContentLocation } from '../find-vitra-types';

interface CommonLocationOpeningHoursProps {
    location: FindVitraContentLocation;
    className?: string;
}

export const styles = {
    wrapper: css`
        ${media.tablet} {
            width: 50%;
            padding-right: 16px;
        }
        vertical-align: top;
        margin-bottom: 32px;
        display: inline-flex;
        flex-wrap: wrap;
        background: white;
    `,
    openingHoursTitle: css`
        font-weight: 600;
        font-size: 17px;
        flex: 0 1 100%;
        text-align: center;
        ${media.tablet} {
            text-align: left;
        }
    `,
    openingHoursData: css`
        display: table;
        width: 100%;
        .data__row {
            display: table-row;
            width: 100%;
            &.data__row--multiline {
                margin: 8px 0;
            }
            .row__day {
                display: table-cell;
                width: 50%;
            }
            .row__hours {
                text-align: right;
                ${media.tablet} {
                    text-align: left;
                }
                display: table-cell;
                width: 50%;
                white-space: nowrap;
            }
        }
    `,
    openingHoursComment: css`
        margin-top: 16px;
        font-size: 12px;
    `
};

const CommonLocationOpeningHours: React.FunctionComponent<CommonLocationOpeningHoursProps> = ({
    location,
    className
}) => {
    const translations = {
        title: useTranslations('community_contact_opening_hours_title', 'Opening hours'),
        openAllDay: useTranslations('community_contact_opening_hours_open_all_day', 'Open all day'),
        closed: useTranslations('community_contact_opening_hours_closed', 'Closed'),
        weekdays: {
            monday: useTranslations('weekdays_shortcurts_monday', 'Mon'),
            tuesday: useTranslations('weekdays_shortcurts_tuesday', 'Tue'),
            wednesday: useTranslations('weekdays_shortcurts_wednesday', 'Wed'),
            thursday: useTranslations('weekdays_shortcurts_thursday', 'Thu'),
            friday: useTranslations('weekdays_shortcurts_friday', 'Fri'),
            saturday: useTranslations('weekdays_shortcurts_saturday', 'Sat'),
            sunday: useTranslations('weekdays_shortcurts_sunday', 'Sun')
        }
    };

    if (!location.openingHours || location.openingHours.length === 0) {
        return null;
    }

    return (
        <div className={cx(styles.wrapper, className)}>
            <h2 className={styles.openingHoursTitle}>{translations.title}</h2>
            <div className={styles.openingHoursData}>
                {location.openingHours.map((row, ri) => (
                    <div key={ri} className={cx('data__row', row.openingHours.length > 1 && 'data__row--multiline')}>
                        <div className="row__day">{translations.weekdays[row.key]}</div>
                        <div className="row__hours">
                            {row.openingHours.length > 0 &&
                                row.openingHours.map((o, i) => {
                                    if (o.open === o.closed) {
                                        return <div>{translations.openAllDay}</div>;
                                    }
                                    return (
                                        <div key={i} className="hours__section">
                                            {o.open}&nbsp;-&nbsp;{o.closed}
                                        </div>
                                    );
                                })}
                            {row.openingHours.length === 0 && (
                                <div className="hours__section">{translations.closed}</div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {location.openingHoursComment && (
                <div className={styles.openingHoursComment}>{location.openingHoursComment}</div>
            )}
        </div>
    );
};

export default CommonLocationOpeningHours;
