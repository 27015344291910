/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import configureDayjs from 'common/utils/time';
import { canUseDOM } from 'exenv';
import get from 'lodash/get';

export type FrontendConfigKeys = {
    env: string;
    code: string;
    configuratorUrl: string;
    deliveryUrl: string;
    translateUrl: string;
    gatewayMerchantId: string;
    adyenClientKeyV2: string;
    adyenClientKey: string;
    adyenEnv: string;
    cartUrl: string;
    shoppingCartUrl: string;
    userUrl: string;
    showPrices: boolean;
    shareUrl: string;
    loginUrl: string;
    authUrl: string;
    voucherUrl: string;
    contactUrl: string;
    uploadUrl: string;
    newsletterUrl: string;
    shareWishlistUrl: string;
    cartLegacyUrl: string;
    checkoutUrl: string;
    cartPageUrl: string;
    forgotPassword: string;
    wishlistUrl: string;
    accountUrl: string;
    searchApiUrl: string;
    bookConsultationUrl: string;
    findVitraCityData: string;
    findVitraUrl: string;
    findVitraContactUrl: string;
    productMaterialsUrl: string;
    wishlsitPrintUrl: string;
    wishlistPrintUrl: string;
    checkoutSuccessUrl: string;
    staticDomain: string;
    showWishlist: string;
    bff: string;
    hasUsercentrics: boolean;
    googleAnalyticsId: string;
    site: string;
    isLoggedIn: boolean;
    countriesData: any;
    country: string;
    CFCountry: string;
    googleMapsApiKey: string;
    features: string[];
    // TODO: add these config vars in backend
    contactServiceUrl: string;
    careRepairUrl: string;
    productsOverviewUrl: string;
    searchPageUrl: string;
    cfTurnstileSiteKey: string;
    paqatoCode: string;
    sentryDns: string;
    currentRelease: string;
    professionalsUrl: string;
    registerUrl: string;
    baseUrl: string;
    myVitraUrl: string;
    googleMapsKey: string;
};

export const getLocale = (): string => {
    const code = useConfig('code', 'de-de');
    const locale = code.split('-')[0];
    return locale;
};

export const getCountryName = (country: string): string => {
    try {
        const countriesData = JSON.parse(useConfig('countriesData', '{}'));
        return countriesData[country];
    } catch (err) {
        return country;
    }
};

/**
 * This function is called when the frontend is started
 * and BEFORE any widget is renderer. It is used to configure things like
 * dayjs upfront because changing the global locale doesn't affect existing instances
 */
export const boostrapBeforeWidgetsRender = () => {
    const locale = getLocale();
    configureDayjs(locale);
};

/**
 * Gets a key from the window.boostrap variable - only works in the drontend
 * @param key The Key for the Boostrap variable
 * @param defaultValue the default values
 */
export const useBoostrap = (key: string, defaultValue: any): any => {
    if (!canUseDOM) {
        return defaultValue;
    }

    return get((window as any).bootstrap, key, defaultValue);
};

export function useConfig<T>(key: keyof FrontendConfigKeys, defaultValue: T): T {
    if (!canUseDOM || process.env.NODE_ENV === 'test') {
        return defaultValue;
    }
    const configValue = get((window as any).config, key, undefined);
    if (configValue === undefined && process.env.NODE_ENV !== 'test') {
        console.warn(`Config value for: ${key} is not set, falback to default value: ${defaultValue}`);
        return defaultValue as T;
    }
    return configValue as T;
}

export const isNotProduction = useConfig<string>('env', 'development') !== 'production';
