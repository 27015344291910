import { getBffLanguageAndCountry } from 'common/graphql/client';
import { useBoostrap, useConfig } from 'common/hooks/useBoostrap';
import { reportError } from 'common/hooks/useError';
import type { LegacyWishlistProductType, WishlistBffContext } from 'common/hooks/useWishlist';
import { useWishlistBffContext } from 'common/hooks/useWishlist';
import Button from 'common/primitives/buttons';
import { SelectInput, TextInput, TextareaInput } from 'common/primitives/forms';
import TurnstileInput from 'common/primitives/forms/inputs/turnstileInput';
import { useTranslations } from 'common/primitives/translations';
import { colors, fontStyles, linkColor, media, rh } from 'common/styles';
import { post } from 'common/utils/fetch';
import validator from 'email-validator';
import { css, cx } from 'linaria';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useStateValue } from '../state/find-vitra.state-provider';

const styles = {
    terms: css`
        text-align: center;
        margin-bottom: ${rh(1)};
        a {
            ${linkColor(colors.black, colors.primary)};
        }
    `,
    messageSuccess: css`
        text-align: center;
    `,
    messageSuccessButton: css`
        margin-top: ${rh(2)};
    `,
    messageError: css`
        padding: ${rh(0.5)};
        text-align: center;
        background: ${colors.formError};
        color: white;
        margin-bottom: ${rh(0.25)};
    `,
    form: css`
        margin-bottom: ${rh(0.5)};
        ${media.desktop} {
            display: flex;
            margin-left: -16px;
            align-items: flex-start;
            flex-wrap: wrap;
        }
    `,
    formColumn: css`
        ${media.desktop} {
            margin-left: 16px;
            flex: 1 0 calc(${100 / 4}% - 16px);
        }
    `,
    formSubmit: css`
        width: auto;
        min-width: 300px;
        margin: 0 auto;
        display: flex;
        margin-bottom: ${rh(1)};
    `,
    formCaptcha: css`
        margin-bottom: ${rh(1)} !important;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .errorMessage {
            flex-basis: 100%;
        }
    `
};

interface CommonContactFormProps {
    className?: string;
    wishlistProductIds?: string[];
    onSuccess?: () => void;
}

const CommonContactForm = (props: CommonContactFormProps) => {
    const t = {
        retailer_wishlistProducts_success: useTranslations(
            'retailer_wishlistProducts_success',
            'Wish list sent successfully.'
        ),
        retailer_contact_success: useTranslations(
            'retailer_contact_success',
            'Thank you very much for your contact enquiry.'
        ),
        retailer_form_back: useTranslations('retailer_form_back', 'Back'),
        retailer_form_message_label: useTranslations('retailer_form_message_label', 'Your message'),
        retailer_form_title_label: useTranslations('retailer_form_title_label', 'Form of address'),
        retailer_form_title_empty: useTranslations('retailer_form_title_empty', 'Please select'),
        retailer_form_title_mr: useTranslations('retailer_form_title_mr', 'Mr'),
        retailer_form_title_ms: useTranslations('retailer_form_title_ms', 'Ms'),
        retailer_form_first_name_label: useTranslations('retailer_form_first_name_label', 'First name'),
        retailer_form_last_name_label: useTranslations('retailer_form_last_name_label', 'Surname'),
        retailer_form_company_label: useTranslations('retailer_form_company_label', 'Company'),
        retailer_form_street_label: useTranslations('retailer_form_street_label', 'Street address'),
        retailer_form_street_no_label: useTranslations('retailer_form_street_no_label', 'Street number'),
        retailer_form_postcode_label: useTranslations('retailer_form_postcode_label', 'Postcode'),
        retailer_form_city_label: useTranslations('retailer_form_city_label', 'City'),
        retailer_form_phone_label: useTranslations('retailer_form_phone_label', 'Phone number'),
        retailer_form_email_label: useTranslations('retailer_form_email_label', 'E-mail'),
        retailer_form_country_label: useTranslations('retailer_form_country_label', 'Country'),
        retailer_form_terms: useTranslations(
            'retailer_form_terms',
            'Data protection: <p>We collect, process and/or use personal data only in compliance with the laws regarding data protection. For more information, please refer to our Privacy Statement which you can view <a href="/privacy" target="_blank">here</a>. At your request, we will be happy to send you a copy.'
        ),
        retailer_error_submit: useTranslations('retailer_error_submit', 'There was an error sending your form.'),
        retailer_form_send: useTranslations('retailer_form_send', 'Send'),
        contact_error_human_verification: useTranslations(
            'contact_error_human_verification',
            'Please complete the human verification'
        )
    };

    const wishlistContext: WishlistBffContext = useWishlistBffContext();
    const [state, dispatch]: any = useStateValue();

    const onClose = () => {
        dispatch({ type: 'UX_TOGGLE_OVERLAY', overlayName: '' });
    };

    const countries = useBoostrap('countries', []).map((c: any) => ({ name: c.name, value: c.iso }));

    const postUrl = useConfig('findVitraContactUrl', '/api/v1/find-vitra/contact');
    const locale = useConfig('code', 'de-de').split('-')[0];

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] = useState('idle');
    const onSubmit = async (payload: any) => {
        setIsSubmitting(true);
        try {
            const hasWishlist = props.wishlistProductIds && props.wishlistProductIds.length > 0;
            let shareURL = '';

            if (hasWishlist) {
                const productsToShare = props.wishlistProductIds!.map((p: any) => ({
                    id: p.id,
                    sku: p.id,
                    productType: 'product' as LegacyWishlistProductType
                }));
                shareURL = (await wishlistContext.createShareWishlist(productsToShare)) as string;
            }

            const postPayload = {
                ...payload,
                ...{
                    topic: 'Product_Dealer',
                    language: locale.toUpperCase(),
                    type: 'Vitra.com Dealer Search',
                    dealer: state.ux.activeLocation._code,
                    currentUrl: window.location.href,
                    email_retailer: state.ux.activeLocation.email_retailer
                },
                ...(hasWishlist && {
                    topic: 'Product Price / Offer',
                    type: 'Vitra.com Wishlist',
                    currentUrl: shareURL
                })
            };

            await post(postUrl, postPayload);
            setSubmitStatus('success');
            setIsSubmitting(false);
            if (props.onSuccess) {
                props.onSuccess();
            }
        } catch (err) {
            reportError(err, 'contact');
            setSubmitStatus('error');
            setIsSubmitting(false);
        }
    };

    const { country } = getBffLanguageAndCountry();
    const { register, errors, handleSubmit, setValue, clearError } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        defaultValues: {
            country
        }
    });

    const [submitCount, setSubmitCount] = useState(0);
    const countSubmits = (callback: any) => (e: any) => {
        e.preventDefault();
        setSubmitCount(submitCount + 1);
        callback(e);
    };

    if (submitStatus === 'success') {
        return (
            <div className={cx(props.className, styles.messageSuccess, fontStyles.h3)}>
                {props.wishlistProductIds ? t.retailer_wishlistProducts_success : t.retailer_contact_success}
                <Button className={styles.messageSuccessButton} onClick={onClose} testId="findVitraContactBack">
                    {t.retailer_form_back}
                </Button>
            </div>
        );
    }

    return (
        <form className={props.className} onSubmit={countSubmits(handleSubmit(onSubmit))}>
            <div className={styles.form}>
                <div className={styles.formColumn}>
                    <TextareaInput
                        name="message"
                        label={`${t.retailer_form_message_label} *`}
                        errors={errors}
                        inputRef={register({
                            required: true
                        })}
                    />
                </div>
                <div className={styles.formColumn}>
                    <SelectInput
                        name="title"
                        label={`${t.retailer_form_title_label} *`}
                        placeholder={t.retailer_form_title_empty}
                        errors={errors}
                        options={[
                            { name: t.retailer_form_title_mr, value: 'Mr.' },
                            { name: t.retailer_form_title_ms, value: 'Ms.' }
                        ]}
                        inputRef={register({
                            required: true
                        })}
                    />

                    <TextInput
                        name="first_name"
                        label={`${t.retailer_form_first_name_label} *`}
                        errors={errors}
                        maxLength={40}
                        inputRef={register({
                            required: true
                        })}
                    />
                    <TextInput
                        name="last_name"
                        label={`${t.retailer_form_last_name_label} *`}
                        errors={errors}
                        maxLength={80}
                        inputRef={register({
                            required: true
                        })}
                    />
                    <TextInput
                        name="company"
                        label={t.retailer_form_company_label}
                        errors={errors}
                        inputRef={register}
                    />
                </div>
                <div className={styles.formColumn}>
                    <TextInput name="street" label={t.retailer_form_street_label} errors={errors} inputRef={register} />
                    <TextInput
                        name="street_no"
                        label={t.retailer_form_street_no_label}
                        errors={errors}
                        inputRef={register}
                    />

                    <TextInput
                        name="postcode"
                        label={t.retailer_form_postcode_label}
                        maxLength={10}
                        errors={errors}
                        inputRef={register}
                    />
                    <TextInput name="city" label={t.retailer_form_city_label} errors={errors} inputRef={register} />
                </div>
                <div className={styles.formColumn}>
                    <TextInput
                        name="phone"
                        label={t.retailer_form_phone_label}
                        errors={errors}
                        inputRef={register({
                            pattern: /^[0-9() +-.:/]+$/
                        })}
                    />
                    <TextInput
                        name="email"
                        label={`${t.retailer_form_email_label} *`}
                        errors={errors}
                        inputRef={register({
                            required: true,
                            validate: (v: string) => validator.validate(v)
                        })}
                    />
                    <SelectInput
                        name="country"
                        errors={errors}
                        options={countries}
                        label={t.retailer_form_country_label}
                        inputRef={register}
                    />
                </div>
            </div>
            <TurnstileInput
                className={styles.formCaptcha}
                name="turnstile"
                setValue={setValue}
                errors={errors}
                clearError={clearError}
                showErrorMessage={submitCount > 1}
                inputRef={register({
                    required: t.contact_error_human_verification
                })}
            />
            <div
                className={cx(fontStyles.small, styles.terms)}
                dangerouslySetInnerHTML={{ __html: t.retailer_form_terms }}
            />
            {submitStatus === 'error' && <div className={styles.messageError}>{t.retailer_error_submit}</div>}
            <Button className={styles.formSubmit} isLoading={isSubmitting} testId="findVitraSubmit" buttonType="submit">
                {t.retailer_form_send}
            </Button>
        </form>
    );
};

export default CommonContactForm;
