import { useTranslations } from 'common/primitives/translations';
import React from 'react';
import { useStateValue } from '../state/find-vitra.state-provider';
import CommonPagination from './commonPagination';

interface CommonSearchResultPaginationProps {
    className?: string;
}

const CommonSearchResultPagination: React.FunctionComponent<CommonSearchResultPaginationProps> = ({ className }) => {
    const [state, dispatch]: any = useStateValue();
    const currentPage = state.pagination.page;
    const numPages = state.locationsFiltered.chunked.length;

    if (numPages === 1) {
        return <div className={className} />;
    }

    return (
        <CommonPagination
            className={className}
            currentPage={currentPage}
            numPages={numPages}
            translationNext={useTranslations('community_search_results_pagination_next', 'Next')}
            translationPrev={useTranslations('community_search_results_pagination_previous', 'Previous')}
            onSetPage={(page: number) => dispatch({ type: 'SET_PAGE', page })}
        />
    );
};

export default CommonSearchResultPagination;
