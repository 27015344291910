import { injectBackgroundPicture } from 'common/styles';
import sortImageVariations from 'common/utils/sortImageVariations';
import { css, cx } from 'linaria';
import React, { Fragment } from 'react';

interface PictureProps {
    /**
     * The ID of the image
     */
    id?: string;
    /**
     * A forward ref for the image
     */
    ref?: any;
    /**
     * A Classname for the <picture> Tag
     */
    className?: string;
    /**
     * The Image
     */
    image: Image;
    /**
     * If the Picture is lazy loaded
     */
    lazy?: boolean;
    /**
     * Set's the image as the
     * background and retruns a div
     */
    background?: boolean;
    /**
     * Explicity defines the alt tag
     */
    alt?: string;
}

const styles = {
    picture: css`
        display: block;
        width: 100%;
        height: auto;
        font-size: 0;
    `
};

const VitraPicture: React.FunctionComponent<PictureProps> = ({ className, background, id, ref, image, lazy, alt }) => {
    if (!image) {
        return <div />;
    }
    const srcSet =
        (typeof image.srcSet !== 'undefined' && sortImageVariations(image.srcSet, { type: 'picture' })) || [];
    const firstImage = srcSet[0] || image;

    if (background) {
        const { backgroundImage, backgroundClass } = injectBackgroundPicture(image);
        return (
            <Fragment>
                {backgroundImage}
                <div className={cx(className, backgroundClass)} />
            </Fragment>
        );
    }

    return (
        <picture ref={ref} id={id} className={className}>
            {srcSet.map((s) => {
                return <source media={s.mq} srcSet={s.src} data-src-height={s.height} data-src-width={s.width} />;
            })}
            <img
                src={firstImage.src}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    if (image.fallback && currentTarget.src !== image.fallback) {
                        currentTarget.src = image.fallback;
                    }
                }}
                loading={lazy ? 'lazy' : 'eager'}
                alt={alt || image.alt || ''}
                width={image.width}
                height={image.height}
                className={cx(styles.picture)}
            />
        </picture>
    );
};
export default VitraPicture;
